<template>
  <div @scroll="loadMore" ref="refScroll">
    <!-- title -->
    <div class="pagdiv" ref="refTitle">
      <span class="pageTitle">{{ $t("realTime.title") }}</span>
    </div>

    <!-- select & filter -->
    <div class="realtime_Wrapper" ref="refFilterBar">
      <div class="realtime_Filter">
        <div class="realtime_Multiselect">
          <multiselect
            ref="multiselect"
            v-model="multiselectValue"
            :options="getFactoryInfoNameList"
            @search-change="searchChange"
            @select="searchSelect"
            @remove="searchRemove"
            placeholder="案場名稱"
            :max-height="600"
            selectLabel=""
            deselectLabel="remove"
            :preserveSearch="true"
            :closeOnSelect="false"
            :clearOnSelect="false"
            :multiple="true"
          >
            <span slot="noResult"> 查詢無資料，請重新搜尋 </span>
          </multiselect>
        </div>
        <!-- 資料筆數 -->
        <div class="realtime_DataNum text-nowrap">
          {{ $t("realTime.dataFound") }}: {{ filterSearch.length }}
        </div>
        <!-- 異常狀態 -->
        <div class="realtime_Status text-nowrap">
          異常狀態:
          <b-form-select
            class="realtime_Status_Select ml-2 mr-3"
            v-model="selectedAlert"
            @change="factoryAlertSearch"
            :disabled="alertLevelSelectDisAble"
            id="alertLevelSelect"
          >
            <template #first>
              <option :value="null" disabled>請選擇</option>
            </template>
            <option value="3">部分通訊異常</option>
            <option value="7">系統短暫斷線</option>
            <option value="5">資料缺漏回補</option>
            <option value="2">發電數據異常</option>
            <option value="9">發電效率不佳</option>
            <option value="6">系統未連線</option>
            <option value="">- 取消選取 -</option>
          </b-form-select>
        </div>
        <!-- 全部載入 button (desktop) -->
        <div
          v-if="userDetail.role === 'Whole_Admin'"
          class="loadAll_Btn--desktop"
        >
          <b-button
            :disabled="isLoadingData"
            @click="getAllData"
            class="loadAll_Btn"
            :class="{ 'loadAll_Btn--disabled': isLoadingData }"
          >
            <i class="fas fa-solid fa-spinner"></i>
            全部載入
          </b-button>
        </div>
      </div>

      <div class="realtime_Info">
        <ul>
          <li>
            <div
              v-b-tooltip.hover.bottom
              title="系統正常營運"
              class="d-flex align-items-center"
            >
              <i
                class="fas fa-check-circle mr-1"
                style="font-size: 20px; color: var(--success)"
              ></i>
              <div>
                {{ $t("realTime.status.systemNormal") }} ({{ alarmCount(0) }})
              </div>
            </div>

            <div
              v-b-tooltip.hover.bottom
              title="停電後 Buffer 回補、斷網後計算回補、特殊計算回補、  三日內無資料"
              class="d-flex align-items-center"
            >
              <img
                class="mr-1"
                src="../assets/file-error.svg"
                alt="資料缺漏回補圖示"
                width="20"
              />
              <div>
                {{ $t("realTime.status.backfill") }} ({{ alarmCount(5) }})
              </div>
            </div>
          </li>

          <li>
            <div
              v-b-tooltip.hover.bottom
              title="依通訊裝置異常告警數值定義(不含 Inv 斷訊)"
              class="d-flex align-items-center"
            >
              <i
                class="fas fa-radiation-alt mr-1"
                style="font-size: 20px; color: var(--yellow)"
              ></i>
              <div>
                {{ $t("realTime.status.partialSystemError") }} ({{
                  alarmCount(3)
                }})
              </div>
            </div>

            <div
              v-b-tooltip.hover.bottom
              title="Inv 斷訊(null)、Inv 未發電"
              class="d-flex align-items-center"
            >
              <img
                class="mr-1"
                src="../assets/electrical.svg"
                alt="發電數據異常圖示"
                width="20"
              />
              <div>
                {{ $t("realTime.status.noPowerData") }} ({{ alarmCount(2) }})
              </div>
            </div>
          </li>

          <li>
            <div
              v-b-tooltip.hover.bottom
              title="案場斷網 30 分鐘內"
              class="d-flex align-items-center"
            >
              <img
                class="mr-1"
                src="../assets/hourglass.svg"
                alt="系統短暫斷線圖示"
                width="20"
              />
              <div>
                {{ $t("realTime.status.deviceDisconnectedShort") }} ({{
                  alarmCount(7)
                }})
              </div>
            </div>

            <div
              v-b-tooltip.hover.bottom
              title="案場斷網大於 30 分鐘"
              class="d-flex align-items-center"
            >
              <img
                class="mr-1"
                src="../assets/smart-plug.svg"
                alt="系統未連線圖示"
                width="20"
              />
              <div>
                {{ $t("realTime.status.deviceDisconnected") }} ({{
                  alarmCount(6)
                }})
              </div>
            </div>
          </li>

          <li>
            <div
              v-b-tooltip.hover.bottom.html="{
                title:
                  '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標',
              }"
              class="d-flex align-items-center"
            >
              <img
                class="mr-1"
                src="../assets/poor-power.svg"
                alt="發電效率不佳圖示"
                width="20"
              />
              <div>
                {{ $t("realTime.status.poorPower") }} ({{ alarmCount(9) }})
              </div>
            </div>
          </li>
        </ul>

        <!-- 全部載入 button (mobile) -->
        <div
          v-if="userDetail.role === 'Whole_Admin'"
          class="loadAll_Btn--mobile"
        >
          <b-button
            :disabled="isLoadingData"
            @click="getAllData"
            class="loadAll_Btn"
            :class="{ 'loadAll_Btn--disabled': isLoadingData }"
          >
            <i class="fas fa-solid fa-spinner"></i>
            全部載入
          </b-button>
        </div>
        <!-- 告警 button -->
        <b-button v-if="hasRoleAdmin" class="error_Btn" @click="openAllModal">
          <i class="fa fa-bell" aria-hidden="true"></i>
          告警事件
        </b-button>
      </div>
    </div>

    <!-- loading overlay -->
    <b-col
      cols="12"
      v-show="overlayShow"
      v-for="i in 7"
      :key="i"
      style="padding: 0px; margin: 10px"
    >
      <b-skeleton-wrapper :loading="true">
        <template #loading>
          <b-skeleton-table
            :rows="1"
            :columns="11"
            :table-props="{ bordered: true, striped: true }"
            v-if="true"
          ></b-skeleton-table>
        </template>
      </b-skeleton-wrapper>
    </b-col>

    <!-- if no data -->
    <b-alert
      v-if="filterSearch.length === 0 && !overlayShow && selectedAlert === ''"
      show
      variant="success"
      style="text-align: center; margin-top: 15px; font-weight: 700"
      >沒有資料，請聯絡管理員</b-alert
    >

    <div ref="refData">
      <div
        v-for="(i, idx) in filterSearch"
        :key="idx"
        class="newRealTimeStyle"
        :id="i.factoryId"
      >
        <table
          @click="
            goToSingleSiteInfo(i.displaySiteName, i.factoryId, i.isDemoSite)
          "
          v-if="!overlayShow"
        >
          <div class="rwdFactoryName">
            {{
              i.displaySiteName === "海創一期"
                ? "海創中心"
                : i.displaySiteName === "海創二期"
                ? "海創人培中心"
                : i.displaySiteName
            }}
          </div>
          <tr>
            <th rowspan="2" style="font-size: 22px; width: 15%">
              {{
                i.displaySiteName === "海創一期"
                  ? "海創中心"
                  : i.displaySiteName === "海創二期"
                  ? "海創人培中心"
                  : i.displaySiteName
              }}<span
                v-if="i.isDemoSite && hasRoleAdmin"
                class="ml-2 peekDisplayNameBtn"
                v-b-tooltip.right="i.factoryName"
                ><i class="fas fa-search"></i
              ></span>
            </th>
            <th>設置容量(kWp)</th>
            <th>日照強度<span class="text-nowrap">(W/㎡)</span></th>
            <th>模組溫度(°C)</th>
            <th>直流功率(kW)</th>
            <th>交流功率(kW)</th>
            <th>今日發電量(kWh)</th>
            <th>INV效率(%)</th>
            <th>RA(%)</th>
            <th>今日PR(%)</th>
            <th>案場狀態</th>
          </tr>
          <tr>
            <transition name="slide-fade">
              <td
                data-title="設置容量(kWp)"
                v-if="i.capacityAll && i.displayAlertLevel !== 6"
              >
                {{ i.capacityAll }}
              </td>
              <td
                data-title="設置容量(kWp)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="設置容量(kWp)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="日照強度(W/㎡)"
                v-if="
                  i.IRR &&
                  i.IRR.length &&
                  !i.IRR.includes(null) &&
                  i.displayAlertLevel !== 6
                "
              >
                {{ dataHandle(handleIRRArray(i.IRR) * 1000, 0) }}
              </td>
              <td
                data-title="日照強度(W/㎡)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="日照強度(W/㎡)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="模組溫度(°C)"
                v-if="i.PVTemp && i.displayAlertLevel !== 6"
              >
                {{ i.PVTemp }}
              </td>
              <td
                data-title="模組溫度(°C)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="模組溫度(°C)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="直流功率(kW)"
                v-if="i.dcp && i.dcp.length && i.displayAlertLevel !== 6"
              >
                {{ sumValue(i.dcp) }}
              </td>
              <td
                data-title="直流功率(kW)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="直流功率(kW)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="交流功率(kW)"
                v-if="i.acp && i.acp.length && i.displayAlertLevel !== 6"
              >
                {{ sumValue(i.acp) }}
              </td>
              <td
                data-title="交流功率(kW)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="交流功率(kW)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="今日發電量(kWh)"
                v-if="
                  i.E_today && i.E_today.length && i.displayAlertLevel !== 6
                "
              >
                {{ sumValue(i.E_today) }}
              </td>
              <td
                data-title="今日發電量(kWh)"
                v-else-if="i.displayAlertLevel === 6"
              >
                -
              </td>
              <td data-title="今日發電量(kWh)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="INV效率(%)"
                v-if="
                  i.acp &&
                  i.dcp &&
                  i.acp.length &&
                  i.dcp.length &&
                  i.displayAlertLevel !== 6
                "
              >
                {{
                  dataHandle(
                    (handleInvSum(i.acp) / handleInvSum(i.dcp)) * 100,
                    0
                  )
                }}
              </td>
              <td data-title="INV效率(%)" v-else-if="i.displayAlertLevel === 6">
                -
              </td>
              <td data-title="INV效率(%)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <td
                :key="i.dataTimeFromNow"
                data-title="RA(%)"
                v-if="
                  i.dcp &&
                  i.dcp.length &&
                  i.capacity &&
                  i.capacity.length &&
                  i.IRR &&
                  i.IRR.length &&
                  i.PrRaMon.RA &&
                  i.displayAlertLevel !== 6
                "
              >
                {{
                  handleInHundred(
                    dataHandle(
                      parseFloat(
                        i.dcp.reduce((a, b) => a + b).toPrecision(12) /
                          parseFloat(i.capacityAll.toPrecision(12)) /
                          handleIRRArray(i.IRR)
                      ) * 100,
                      2
                    )
                  )
                }}
              </td>
              <td data-title="RA(%)" v-else-if="i.displayAlertLevel === 6">
                -
              </td>
              <td data-title="RA(%)" v-else>null</td>
            </transition>
            <transition name="slide-fade">
              <!-- <td data-title="今日PR(%)" v-if="typeof i.PrRaMon != 'string' && i.PrRaMon.ACP && i.PrRaMon.IRR && i.capacity && i.capacity.length && i.displayAlertLevel !== 6">{{calculatePR(i.capacity, i.PrRaMon.ACP, i.PrRaMon.IRR)}}</td> -->
              <td
                data-title="今日PR(%)"
                v-if="
                  typeof i.PrRaMon !== 'string' &&
                  i.PrRaMon?.ACP &&
                  i.PrRaMon?.PR &&
                  i.capacity &&
                  i.capacity.length &&
                  i.displayAlertLevel !== 6
                "
              >
                {{ dataHandle(i.PrRaMon.PR * 100, 1) }}
              </td>
              <td data-title="今日PR(%)" v-else-if="i.displayAlertLevel === 6">
                -
              </td>
              <td data-title="今日PR(%)" v-else>null</td>
            </transition>

            <transition name="slide-fade">
              <td
                data-title="案場狀態"
                @click.stop="
                  openSiteModal(i.factoryName, i.displaySiteName, i.isDemoSite)
                "
                v-b-tooltip.right="alertLevelToText(i.displayAlertLevel)"
              >
                <i
                  v-if="i.displayAlertLevel === 0"
                  class="fas fa-check-circle"
                  style="color: var(--success)"
                ></i>
                <img
                  v-else-if="
                    i.displayAlertLevel === 1 || i.displayAlertLevel === 2
                  "
                  src="../assets/electrical.svg"
                  alt="發電數據異常圖示"
                  width="20"
                  class="pb-1"
                />
                <i
                  v-else-if="i.displayAlertLevel === 3"
                  class="fas fa-radiation-alt"
                  style="color: var(--yellow)"
                ></i>
                <img
                  v-else-if="i.displayAlertLevel === 5"
                  src="../assets/file-error.svg"
                  alt="資料缺漏回補圖示"
                  width="20"
                  class="pb-1"
                />
                <img
                  v-else-if="i.displayAlertLevel === 6"
                  src="../assets/smart-plug.svg"
                  alt="系統未連線圖示"
                  width="20"
                  class="pb-1"
                />
                <img
                  v-else-if="i.displayAlertLevel === 7"
                  src="../assets/hourglass.svg"
                  alt="系統短暫斷線圖示"
                  width="20"
                  class="pb-1"
                />
                <i
                  v-else-if="i.displayAlertLevel === 4"
                  class="fas fa-search-minus"
                  style="color: var(--purple)"
                ></i>
                <img
                  v-else-if="i.displayAlertLevel === 9"
                  src="../assets/poor-power.svg"
                  alt="發電效率不佳圖示"
                  width="20"
                  class="pb-1"
                />
              </td>
            </transition>
          </tr>
        </table>
      </div>

      <!-- if isLoadingData -->
      <div v-if="isLoadingData" style="text-align: center; margin: 15px">
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </div>
    </div>

    <!-- ----- site alert modal ----- -->
    <b-modal id="siteAlertModal" size="xl" :title="modalTitle" hide-footer>
      <div class="d-flex flex-wrap">
        <div class="d-flex align-items-center my-2 mr-2">
          <span class="mr-2 font-weight-bold">選擇日期:</span>
          <date-picker
            value-type="format"
            v-model="alertBeginTime"
            format="YYYY-MM-DD"
            type="date"
            style="width: 150px"
          ></date-picker>
        </div>
        <div class="d-flex align-items-center my-2 mr-3 mr-lg-4">
          <span class="mr-2">~</span>
          <date-picker
            value-type="format"
            v-model="alertEndTime"
            format="YYYY-MM-DD"
            type="date"
            style="width: 150px"
          ></date-picker>
        </div>
        <div class="d-flex align-items-center mr-3 mr-lg-4 my-2 my-xl-3">
          <b-button
            @click="queryModal(modalTitle)"
            class="confirmButton"
            style="width: 60px"
            >查詢</b-button
          >
        </div>
        <div class="d-flex align-items-center mr-3 mr-lg-4 my-2 my-xl-3">
          <span class="mr-2 font-weight-bold text-nowrap">事件篩選:</span>
          <input
            v-model="alertFilterInput"
            placeholder=""
            style="min-width: 80px"
          />
        </div>
        <div
          class="d-flex align-items-center font-weight-bold my-2 ml-auto"
          v-if="!alertModalNoData"
        >
          總告警:
          <span
            class="px-2 text-center"
            style="min-width: 50px; font-family: '微軟正黑體'"
          >
            {{ filteredAlertData.length }}
          </span>
          筆
        </div>
      </div>

      <hr />

      <!-- level btn -->
      <div v-if="!alertModalNoData" class="d-flex flex-wrap">
        <div
          v-for="btn in levelButtons"
          :key="btn.level"
          class="d-flex align-items-center mb-3 mr-4"
        >
          <button
            type="button"
            class="btn btn-sm text-nowrap"
            :class="[
              alertFilterLevelNum.includes(btn.level)
                ? `btn-${btn.color}`
                : `btn-outline-${btn.color}`,
            ]"
            @click="toggleAlertLevelBtn(btn.level)"
          >
            {{ btn.text }}&ensp;( {{ levelSum(btn.level) }} )
          </button>
        </div>
      </div>

      <div v-if="!alertModalNoData">
        <b-table
          class="alertTable"
          :dark="tableMode"
          head-variant="light"
          hover
          :responsive="true"
          bordered
          :fields="alertField"
          :items="showFilteredAlertData"
          :busy="alertTableLoading"
          sort-by="告警啟動時間"
          :sortDesc="true"
          :per-page="alertPerPage"
          :current-page="alertCurrentPage"
          :tbody-tr-class="tbodyTrClass"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner
                class="align-middle"
                style="margin-right: 3px"
              ></b-spinner>
              <strong>讀取中</strong>
            </div>
          </template>
        </b-table>
      </div>
      <b-alert
        v-if="alertModalNoData"
        show
        variant="primary"
        style="text-align: center; margin-top: 15px; font-weight: 700"
        >沒有異常資料，請選擇其他日期</b-alert
      >
      <b-pagination
        v-if="showFilteredAlertData.length"
        v-model="alertCurrentPage"
        :per-page="alertPerPage"
        :total-rows="showFilteredAlertData.length"
        class="mb-5"
      ></b-pagination>
    </b-modal>

    <!-- ----- all alert modal ----- -->
    <b-modal id="allAlertModal" size="xl" title="告警事件" hide-footer>
      <div class="d-flex flex-wrap">
        <div class="d-flex align-items-center my-2 mr-3 mr-sm-4 order-0">
          <span class="mr-2 font-weight-bold">選擇日期:</span>
          <date-picker
            value-type="format"
            v-model="allAlertTime"
            format="YYYY-MM-DD"
            type="date"
            style="width: 150px"
          ></date-picker>
        </div>
        <div
          class="d-flex align-items-center my-2 my-xl-3 mr-2 mr-lg-4 order-2 order-sm-1"
        >
          <b-button
            @click="queryModal('all')"
            class="confirmButton"
            style="width: 60px"
            >查詢</b-button
          >
        </div>
        <div class="d-flex align-items-center my-2 mr-4 order-1">
          <span class="mr-2 font-weight-bold text-nowrap">事件篩選:</span>
          <input v-model="alertFilterInput" placeholder="" />
        </div>
        <div
          class="d-flex align-items-center font-weight-bold my-2 ml-auto order-3"
          v-if="!alertModalNoData"
        >
          總告警:
          <span
            class="px-2 text-center"
            style="min-width: 50px; font-family: '微軟正黑體'"
          >
            {{ filteredAlertData.length }}
          </span>
          筆
        </div>
      </div>

      <hr />

      <!-- level btn -->
      <div v-if="!alertModalNoData" class="d-flex flex-wrap">
        <div
          v-for="btn in levelButtons"
          :key="btn.level"
          class="d-flex align-items-center mb-3 mr-4"
        >
          <button
            type="button"
            class="btn btn-sm text-nowrap"
            :class="[
              alertFilterLevelNum.includes(btn.level)
                ? `btn-${btn.color}`
                : `btn-outline-${btn.color}`,
            ]"
            @click="toggleAlertLevelBtn(btn.level)"
          >
            {{ btn.text }}&ensp;( {{ levelSum(btn.level) }} )
          </button>
        </div>
      </div>

      <div v-if="!alertModalNoData">
        <b-table
          class="alertTable"
          head-variant="light"
          hover
          :responsive="true"
          bordered
          :fields="alertField"
          :items="showFilteredAlertData"
          :busy="alertTableLoading"
          sort-by="告警啟動時間"
          :sortDesc="true"
          :per-page="alertPerPage"
          :current-page="alertCurrentPage"
          :tbody-tr-class="tbodyTrClass"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner
                class="align-middle"
                style="margin-right: 3px"
              ></b-spinner>
              <strong>讀取中</strong>
            </div>
          </template>
        </b-table>
      </div>
      <b-alert
        v-if="alertModalNoData"
        show
        variant="primary"
        style="text-align: center; margin-top: 15px; font-weight: 700"
        >沒有異常資料，請選擇其他日期</b-alert
      >
      <b-pagination
        v-if="showFilteredAlertData.length"
        v-model="alertCurrentPage"
        :per-page="alertPerPage"
        :total-rows="showFilteredAlertData.length"
        class="mb-5"
      ></b-pagination>
    </b-modal>

    <!-- backTop button -->
    <div class="backTop" @click="backTop" v-if="!clientTop">
      <i class="fas fa-chevron-up"></i>
    </div>
  </div>
</template>

<script>
// import store from '../store/index'
import { getFactoryErrorApi } from '../api'
import Multiselect from 'vue-multiselect'
import {
  BSkeletonWrapper,
  BButton,
  BSkeletonTable,
  BTable,
  BSpinner,
  BAlert,
  BPagination
} from 'bootstrap-vue'
import i18n from '../lang/lang'
import { DateTime } from 'luxon'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { dbDataHandle } from '../store/function/common/index'
import { isArray } from 'lodash'
export default {
  name: 'realtime',
  components: {
    'b-skeleton-wrapper': BSkeletonWrapper,
    BButton,
    BSkeletonTable,
    BTable,
    BSpinner,
    BAlert,
    BPagination,
    Multiselect
  },
  data () {
    return {
      whichFactory: '',
      selectedAlert: null,
      exitFunction: false,
      alertPerPage: 10,
      alertCurrentPage: 1,
      searchValue: [], // 搜尋資料用，因 multiselectValue 會延遲
      multiselectValue: [],
      alertField: [
        {
          key: '告警啟動時間',
          sortable: true,
          thClass: 'thClass',
          label: '告警啟動時間'
        },
        { key: '事件', sortable: true, thClass: 'thClass', label: '事件' }
      ],
      modalTitle: '',
      selectTime: DateTime.local().toFormat('yyyy-MM-dd'),
      alertBeginTime: DateTime.local().toFormat('yyyy-MM-dd'),
      alertEndTime: DateTime.local().toFormat('yyyy-MM-dd'),
      allAlertTime: DateTime.local().toFormat('yyyy-MM-dd'),
      alertData: [],
      alertTableLoading: false,
      disabledDates: {
        to: new Date(2020, 11, 28)
      },
      // headColor: 'light',
      tableMode: false,
      alertModalNoData: false,
      isLoadingData: false,
      clientTop: true,
      multipleSearchArray: [],
      alertLevelSelectDisAble: false,
      alertFilterInput: '',
      alertFilterLevelNum: [2], // activated 的按鈕 level num list
      hasKeepingAlive: false
    }
  },
  computed: {
    ...mapState([
      'factoryData',
      'overlayShow',
      'userDetail',
      'factoryInfo',
      'roleComponentMenu',
      'roleOthersMenu',
      'hasRoleAdmin',
      'hasDemoPermission',
      'hasDemoCallApi',
      'alarmAmountData' // 告警數量、案場
    ]),
    ...mapGetters(['getFactoryInfoNameList']),
    levelButtons () {
      return [
        { level: 2, color: 'red', text: '影響發電' },
        { level: 9, color: 'darkgreen', text: '發電效率不佳' },
        { level: 3, color: 'yellow', text: '不影響發電' },
        // { level: 3, color: 'yellow', text: 'RA PR 系統觸發告警' },
        { level: 6, color: 'grey', text: '系統未連線' },
        { level: 7, color: 'purple', text: '系統短暫斷線' }
        // { level: 8, color: 'blue', text: 'PA PR 系統觸發告警' }
      ].filter((obj) => this.roleOthersMenu.includes(obj.text)) // 過濾告警按鈕權限
    },
    // 依權限列出 可看 level num list
    hasAlertLevelNumList () {
      return this.levelButtons.map((obj) => obj.level)
    },
    hasAllFactoryPermission () {
      return this.userDetail.factoriesList.includes('ALL')
    },
    filterSearch () {
      // 這裡用 multiselectValue 作為篩選

      // no alert no select
      if (!this.selectedAlert && this.multiselectValue.length === 0) {
        return this.factoryData
      }

      // only select
      if (!this.selectedAlert && this.multiselectValue.length !== 0) {
        return this.factoryData.filter((data) =>
          this.multiselectValue.includes(data.displaySiteName)
        )
      }

      // only alert
      if (this.selectedAlert && this.multiselectValue.length === 0) {
        return this.factoryData.filter((data) =>
          this.selectedAlert.includes(data.displayAlertLevel)
        )
      }

      // alert && select
      return this.factoryData.filter(
        (data) =>
          this.multiselectValue.includes(data.displaySiteName) &&
          this.selectedAlert.includes(data.displayAlertLevel)
      )
    },
    // 總告警顯示
    filteredAlertData () {
      if (this.alertFilterInput === '') {
        return this.alertData.filter((obj) =>
          this.hasAlertLevelNumList.includes(obj['等級'])
        )
      }
      return this.alertData.filter(
        (obj) =>
          obj['事件'].match(this.alertFilterInput) &&
          this.hasAlertLevelNumList.includes(obj['等級'])
      )
    },
    // table 顯示的
    showFilteredAlertData () {
      // has input & click level button
      if (this.alertFilterInput !== '' && this.alertFilterLevelNum.length) {
        return this.alertData.filter(
          (item) =>
            item['事件'].match(this.alertFilterInput) &&
            this.hasAlertLevelNumList.includes(item['等級']) && // 權限 可看 level num list
            this.alertFilterLevelNum.includes(item['等級']) // activated 的按鈕 level num list
        )
      }

      // click level button
      if (this.alertFilterLevelNum.length) {
        return this.alertData.filter(
          (item) =>
            this.hasAlertLevelNumList.includes(item['等級']) &&
            this.alertFilterLevelNum.includes(item['等級'])
        )
      }

      // no clicked level button
      return []
    }
  },
  methods: {
    ...mapActions(['asyncSpecificLastDoc', 'asyncInfo', 'getAllAlarm']),
    ...mapMutations([
      'LAST_MON_ACP_IRR',
      'UPDATE_DATA_ANALYSIS_STATE',
      'RESET_FACTORY_DATA'
    ]),
    searchChange (searchQuery) {
      this.multipleSearch()
    },
    searchSelect (selectedOption) {
      this.searchValue.push(selectedOption)
      this.multipleSearch()
    },
    searchRemove (removedOption) {
      this.searchValue = this.searchValue.filter(
        (item) => item !== removedOption
      )
    },
    alertLevelToText (displayAlertLevel) {
      if (displayAlertLevel === 0) return '系統正常營運'
      if (displayAlertLevel === 2) return '發電數據異常'
      if (displayAlertLevel === 3) return '部分通訊異常'
      if (displayAlertLevel === 5) return '資料缺漏回補'
      if (displayAlertLevel === 6) return '系統未連線'
      if (displayAlertLevel === 7) return '系統短暫斷線'
      if (displayAlertLevel === 9) return '發電效率不佳'
    },
    activatedLevelButton () {
      const findLevelSum = this.levelButtons.find(
        (obj) => this.levelSum(obj.level) > 0
      )
      if (findLevelSum) return [findLevelSum.level]
      return []
    },
    goToSingleSiteInfo (displaySiteName, fid, isDemoSite) {
      this.whichFactory = fid
      const singleData = this.factoryData.filter(
        (x) => x.displaySiteName === displaySiteName
      )
      const invLen = singleData[0].Eff?.length ?? 0
      const pvLen = singleData[0].pv_a
        ? Math.max(...singleData[0].pv_a.map((x) => x.length))
        : singleData[0].mppt_a
          ? Math.max(...singleData[0].mppt_a.map((x) => x.length))
          : 0
      const anemometerLen = isArray(singleData[0].Anemometer)
        ? singleData[0].Anemometer.length
        : singleData[0].Anemometer || singleData[0].Anemometer === 0
          ? 1
          : 0

      const routerQueryParams = {
        siteName: singleData[0].displaySiteName,
        invLen,
        pvLen,
        anemometerLen,
        lang: i18n.locale,
        fid,
        demo: isDemoSite ? 'true' : 'false'
      }
      this.toSiteInfo(routerQueryParams)

      this.LAST_MON_ACP_IRR(singleData[0]?.PrRaMon)
      this.UPDATE_DATA_ANALYSIS_STATE()
    },
    toSiteInfo (routerQueryParams) {
      if (this.roleComponentMenu.length) {
        const to = this.roleComponentMenu[0]
        this.$router.push({
          path: `/${i18n.locale}/siteinfo/${to}`,
          query: routerQueryParams
        })
      } else {
        this.$swal('權限不足')
        this.$router.push({
          path: `/${i18n.locale}/realtime`
        })
      }
    },
    async openSiteModal (openFactoryName, openDisplaySiteName, isDemoSite) {
      if (this.roleOthersMenu.includes('即時資訊 - 案場狀態告警')) {
        this.alertData = []
        this.alertModalNoData = false
        this.modalTitle = openDisplaySiteName
        this.selectTime = DateTime.local().toFormat('yyyy-MM-dd')
        this.alertFilterInput = ''
        this.alertCurrentPage = 1
        this.$bvModal.show('siteAlertModal')
        this.alertTableLoading = true
        const factoryError = await getFactoryErrorApi(
          this.selectTime,
          this.selectTime,
          openFactoryName
        )
        const errorArr = factoryError.data.data.filter(
          (x) => x.factoryName === openFactoryName
        )
        if (errorArr) {
          errorArr.forEach((item) => {
            this.alertData.push({
              告警啟動時間: DateTime.fromISO(item.timestamp).toFormat(
                'yyyy-MM-dd HH:mm:ss'
              ),
              事件: isDemoSite
                ? item.desc.replace(openFactoryName, openDisplaySiteName)
                : item.desc,
              等級: item.alertLevel === 1 ? 2 : item.alertLevel
            })
          })
        }
        this.alertFilterLevelNum = this.activatedLevelButton()
        this.alertTableLoading = false
        this.alertModalNoData = this.alertData.length === 0
      }
    },
    async openAllModal () {
      this.alertData = []
      this.selectTime = DateTime.local().toFormat('yyyy-MM-dd')
      this.alertFilterInput = ''
      this.alertCurrentPage = 1
      this.alertModalNoData = false
      this.$bvModal.show('allAlertModal')
      this.alertTableLoading = true
      const factoryError = await getFactoryErrorApi(
        this.selectTime,
        this.selectTime,
        'all'
      )
      factoryError.data.data.forEach((x) => {
        if (
          this.userDetail.factoriesList.includes(x.factoryName) ||
          this.hasAllFactoryPermission
        ) {
          this.alertData.push({
            告警啟動時間: DateTime.fromISO(x.timestamp).toFormat(
              'yyyy-MM-dd HH:mm:ss'
            ),
            事件: x.desc,
            等級: x.alertLevel === 1 ? 2 : x.alertLevel // alertLevel 若是 1 則併入 2 內 (1 改為 2)
          })
        }
      })
      this.alertFilterLevelNum = this.activatedLevelButton()
      this.alertTableLoading = false
      this.alertModalNoData = this.alertData.length === 0
    },
    async queryModal (querySiteName) {
      const isQueryAll = querySiteName === 'all'
      const isQueryDemo = querySiteName.includes('展示案場')

      const useQuerySiteName = isQueryDemo
        ? this.factoryInfo.filter(
          (item) => item.displaySiteName === querySiteName
        )?.[0]?.factory
        : querySiteName

      const useQueryStartDate = isQueryAll
        ? this.allAlertTime
        : this.alertBeginTime

      const useQueryEndDate = isQueryAll
        ? this.allAlertTime
        : this.alertEndTime

      this.alertTableLoading = true
      this.alertData = []
      this.alertModalNoData = false
      this.alertCurrentPage = 1

      const factoryError = await getFactoryErrorApi(
        useQueryStartDate,
        useQueryEndDate,
        useQuerySiteName
      )
      const errorArr = isQueryAll
        ? factoryError.data.data
        : factoryError.data.data.filter(
          (x) => x.factoryName === useQuerySiteName
        )
      if (errorArr) {
        errorArr.forEach((x) => {
          this.alertData.push({
            告警啟動時間: DateTime.fromISO(x.timestamp).toFormat(
              'yyyy-MM-dd HH:mm:ss'
            ),
            事件: isQueryDemo
              ? x.desc.replace(useQuerySiteName, querySiteName)
              : x.desc,
            等級: x.alertLevel === 1 ? 2 : x.alertLevel
          })
        })
      }
      this.alertTableLoading = false
      this.alertModalNoData = this.alertData.length === 0
    },
    toggleAlertLevelBtn (levelNum) {
      let hasFilterLevelNum = this.alertFilterLevelNum.includes(levelNum)
      if (hasFilterLevelNum) {
        this.alertFilterLevelNum = this.alertFilterLevelNum.filter(
          (item) => item !== levelNum
        )
      } else {
        this.alertFilterLevelNum.push(levelNum)
      }
    },
    levelSum (num) {
      return this.filteredAlertData.filter((item) => item['等級'] === num)
        .length
    },
    tbodyTrClass (item, type) {
      if (!item || type !== 'row') return
      if (item['等級'] === 2) return 'table-red'
      if (item['等級'] === 3) return 'table-yellow'
      if (item['等級'] === 6) return 'table-grey'
      if (item['等級'] === 7) return 'table-purple'
      if (item['等級'] === 9) return 'table-darkgreen'
      // if (item['等級'] === 8) return 'table-blue'
    },
    sumValue (v) {
      if (!v.length) return 0
      const value = this.dataHandle(v.reduce((a, b) => a + b) * 1)
      if (value <= 0) return 0
      return value
    },
    handleInvSum (v) {
      if (v && v.length) return this.dataHandle(v.reduce((a, b) => a + b))
      return 0
    },
    alarmCount (alarmNum) {
      const includesFactoriesList = (name) =>
        this.userDetail.factoriesList.includes(name)

      const includesDemoList = (name) =>
        this.userDetail.demoList.includes(name)

      // 業主
      const isOwner = !this.hasRoleAdmin
      const useFactories = isOwner ? 'ownerFactories' : 'factories'
      const useAlertCount = isOwner ? 'ownerAlertCount' : 'count'

      // 案場狀態數量 = alarmAmountData 過濾出 (可觀看案場清單 + 展示案場清單) 的數量
      const selectedAlarmNumFactories =
        this.alarmAmountData[alarmNum]?.[useFactories]

      const alarmCountFactories = this.hasAllFactoryPermission
        ? this.alarmAmountData[alarmNum]?.[useAlertCount]
        : selectedAlarmNumFactories?.filter(includesFactoriesList).length

      const alarmCountDemos =
        selectedAlarmNumFactories?.filter(includesDemoList).length

      if (alarmCountFactories || alarmCountDemos) {
        return alarmCountFactories + alarmCountDemos
      }
      return 0
    },
    // changeTableMode () {
    //   this.tableMode = !this.tableMode
    //   this.headColor = this.tableMode ? 'dark' : 'light'
    // },
    dataHandle (val, index) {
      return dbDataHandle(val, index)
    },
    calculatePR (cap, acp, IRR) {
      if (cap && !isNaN(acp) && !isNaN(IRR)) {
        const sumCap = cap.reduce((a, b) => a + b)
        const powerHour = dbDataHandle(acp / sumCap)
        const irr = dbDataHandle(IRR)
        let PR = dbDataHandle(dbDataHandle(powerHour / irr) * 100, 0)
        if (PR >= 100) PR = 100
        return PR
      } else return '-'
    },
    handleIRRArray (irr) {
      const type = Object.prototype.toString
        .call(irr)
        .slice(8, -1)
        .toLowerCase()
      if (irr === undefined || irr === null) return
      if (type && type === 'number') return irr
      if (type && type === 'array') {
        if (irr.includes(null)) return null
        return irr && irr.length
          ? parseFloat(irr.reduce((a, b) => a + b).toPrecision(12)) / irr.length
          : 0
      }
      if (type && type === 'object') return irr.avg ? irr.avg : 0
    },
    handleInHundred (num) {
      if (num >= 100) {
        return 100
      } else {
        return num
      }
    },
    backTop () {
      const element = document.getElementById(this.filterSearch[0].factoryId)
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    },
    handleResize () {
      // console.log('缩放比例:', window.devicePixelRatio)
      // 瀏覽器視窗大小縮放 或 Ctrl +/-
      // 資料高度小於視窗高度 以致於沒有 scroll 可以往下捲再繼續載入資料
      const viewHeight = this.$refs.refScroll.clientHeight
      const contentHeight =
        this.$refs.refTitle.clientHeight +
        this.$refs.refFilterBar.clientHeight +
        this.$refs.refData.clientHeight

      const hasScroll = viewHeight < contentHeight
      if (!hasScroll) return this.getSplitFactoryData()
    },

    async getRealTimeData () {
      this.RESET_FACTORY_DATA()

      const howMuch = 10
      if (this.exitFunction) return
      if (this.$router.history.current.name === 'realtime') {
        const noExistFactory = this.factoryInfo.filter(
          (y) =>
            !this.factoryData
              .map((x) => x.displaySiteName)
              .includes(y.displaySiteName)
        )
        const loadArray = noExistFactory.filter(
          (_, index) => index < howMuch && index >= 0
        )
        if (loadArray.length > 0) {
          await this.loadSearch(loadArray)
        }
      }

      this.alertLevelSelectDisAble = false
    },
    async getAllData () {
      const noExistFactory = this.factoryInfo.filter(
        (y) =>
          !this.factoryData
            .map((x) => x.displaySiteName)
            .includes(y.displaySiteName)
      )
      await this.loadSearch(noExistFactory)
    },
    async loadMore (e) {
      this.clientTop = e.target.scrollTop === 0
      if (
        Math.abs(
          e.target.clientHeight + e.target.scrollTop - e.target.scrollHeight
        ) <= 10
      ) {
        await this.getSplitFactoryData()
      }
    },
    async getSplitFactoryData () {
      if (
        this.exitFunction ||
        this.isLoadingData ||
        this.searchValue.length !== 0 ||
        this.selectedAlert !== null
      ) {
        return
      }

      if (
        this.$router.history.current.name === 'realtime' &&
        !this.isLoadingData
      ) {
        const noExistFactory = this.factoryInfo.filter(
          (y) =>
            !this.factoryData
              .map((x) => x.displaySiteName)
              .includes(y.displaySiteName)
        )

        const loadArray = noExistFactory.filter(
          (_, index) => index < 10 && index >= 0
        )
        if (loadArray.length > 0) await this.loadSearch(loadArray)
      }
    },
    async multipleSearch () {
      // 使用 searchValue 不用 multiselectValue
      // multiselectValue 會延遲，故用 searchValue 另外存取、作為搜尋用
      if (this.isLoadingData || !this.searchValue.length) return

      // filter Info 有找到 searchValue 且 不在展示的 Data 內
      const filterInput = this.factoryInfo
        .filter((info) =>
          info.displaySiteName.includes(this.searchValue.at(-1))
        )
        .filter(
          (info) =>
            !this.factoryData
              .map((data) => data.displaySiteName)
              .includes(info.displaySiteName)
        )

      if (filterInput.length) await this.loadSearch(filterInput)
    },
    async factoryAlertSearch (value) {
      if (this.selectedAlert !== '') {
        this.alertLevelSelectDisAble = true
        await this.getFactoriesByAlert(value)
        this.alertLevelSelectDisAble = false
      }
      if (this.selectedAlert === '') {
        this.selectedAlert = null
      }
    },
    async getFactoriesByAlert (value) {
      // 業主
      const isOwner = !this.hasRoleAdmin
      const useFactories = isOwner ? 'ownerFactories' : 'factories'

      // 可看 ALL or all案場狀態過濾出可看案場+展示案場清單
      const factoryByAlertList = this.hasAllFactoryPermission
        ? this.alarmAmountData[value]?.[useFactories]
        : this.alarmAmountData[value]?.[useFactories].filter(
          (name) =>
            this.userDetail.factoriesList.includes(name) ||
              this.userDetail.demoList.includes(name)
        )

      if (!this.exitFunction) {
        // 從 factoryInfo 取出要 call api 的 {} 們，不包含已呈現在頁面上的 (factoryData)
        const alertFactoryList = this.factoryInfo.filter(
          (y) =>
            factoryByAlertList?.includes(y.factory) &&
            !this.factoryData.map((x) => x.factoryName).includes(y.factory)
        )

        if (alertFactoryList.length > 0) {
          await this.loadSearch(alertFactoryList)
        }
      }
    },
    async loadSearch (array) {
      this.isLoadingData = true
      await this.asyncSpecificLastDoc(array)
      this.isLoadingData = false

      // data 載入完畢後，dom 更新後執行 nextTick
      // 資料高度小於視窗高度 以致於沒有 scroll 可以往下捲再繼續載入資料
      // 置於 loadSearch 後，判斷高度 -> 取得資料直到有產生捲軸 則不再執行 loadSearch (nextTick)
      this.$nextTick(() => {
        const viewHeight = this.$refs.refScroll.clientHeight
        const contentHeight =
          this.$refs.refTitle.clientHeight +
          this.$refs.refFilterBar.clientHeight +
          this.$refs.refData.clientHeight

        const hasScroll = viewHeight < contentHeight
        if (!hasScroll) return this.getSplitFactoryData()
      })
    }
  },
  async created () {
    window.addEventListener('resize', this.handleResize)

    this.alertLevelSelectDisAble = true
    this.isLoadingData = true
    await this.asyncInfo()
    if (this.$store.state.realTimeDataReload) {
      await this.getRealTimeData()

      await this.getAllAlarm()
    }

    this.isLoadingData = false
    this.alertLevelSelectDisAble = false
  },
  async mounted () {
    if (!this.$store.state.realTimeDataReload) {
      const element = document.getElementById(this.$store.state.globalFactory)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    if (JSON.parse(localStorage.getItem('userDetail'))) {
      this.$store.state.realTimeDataReload = false
      this.$store.state.globalFactory = this.whichFactory
    }
    this.exitFunction = true
    this.hasKeepingAlive = true
    next()
  },
  // keep-alive lifecycle enter
  // activated 是 keep-alive 範圍內 才有的，一進入頁面執行，離開是執行 deactivated
  // activated 可用於初始化頁面數據
  async activated () {
    // 一開始建立時也會執行 activated，用 hasKeepingAlive 判斷是否執行
    if (this.hasKeepingAlive) {
      // keep-alive 使 RouteLeave 後 exitFunction === true (->不載入資料) 仍然存在
      // activated 後將 exitFunction 回復預設 (false)
      this.exitFunction = false

      // ex: usermanage 更新資料後，回到 realtime 頁
      // 因有緩存(keep-alive)，不再執行 created、mounted (僅剛開始建立時各執行一次)
      // 若有資料更新 (realTimeDataReload === true)，需重新初始化資料
      if (this.$store.state.realTimeDataReload) {
        this.multiselectValue = []
        this.selectedAlert = null

        await this.getRealTimeData()

        await this.getAllAlarm()
      }

      if (!this.$store.state.realTimeDataReload) {
        const element = document.getElementById(
          this.$store.state.globalFactory
        )
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          })
        }
      }
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style
  src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss" scoped>
:deep(.realtime_Multiselect) {
  // cover vue-multiselect style
  .multiselect__tags {
    min-height: 38px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    background: #fff;
  }

  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #7db9e8;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #2d738f;
  }

  .multiselect__option {
    font-size: 15px;
  }

  .multiselect__option--highlight {
    background: #7db9e8;
    outline: none;
    color: white;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #7db9e8;
    color: white;
  }

  .multiselect__option--selected {
    background: #7db9e8;
    color: white;
    font-weight: 700;
  }

  .multiselect__option--selected:after {
    content: "selected";
    background: #7db9e8;
    color: white;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #b3b6b9;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #b3b6b9;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect__placeholder {
    color: #9fa2a4;
    margin-bottom: 0;
    margin-left: 3px;
    padding-top: 3px;
  }

  .multiselect__input::placeholder {
    color: #9fa2a4;
    font-size: calc(15rem / 16);
  }

  .multiselect__single {
    font-size: calc(15rem / 16);
    color: #434445;
  }
}
</style>

<style lang="scss" scoped>
@import "../assets/scss/RealTime.scss";

.newRealTimeStyle {
  margin: 12px 15px;
  table {
    box-sizing: border-box;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px var(--gray-dark);
    }
  }

  table tr {
    box-sizing: border-box;
    background-color: #cfeaff;
  }
  table th {
    box-sizing: border-box;
    text-shadow: 2px 2px #316fae;
    background: var(--acmeTableTitle);
    color: white;
    font-size: 16.5px;
    text-align: center;
  }

  table td {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 700;
    border-right: solid 1px rgb(135, 204, 111, 0.5);
    text-align: center;
  }
  table td:nth-last-child(1) {
    border: none;
    &:hover {
      background: linear-gradient(
          to bottom,
          #d5dee7 0%,
          #e8ebf2 50%,
          #ede8e2 100%
        ),
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.02) 50%,
          rgba(255, 255, 255, 0.02) 61%,
          rgba(0, 0, 0, 0.02) 73%
        ),
        linear-gradient(
          33deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      background-blend-mode: normal, color-burn;
    }
  }
}

.rwdFactoryName {
  display: none;
}

.peekDisplayNameBtn {
  cursor: help;
  font-size: 14px;
  color: #dfefff;
}

@media only screen and (max-width: 768px) {
  .rwdFactoryName {
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    font-size: 22px;
    display: block;
    background: var(--acmeTableTitle);
    text-align: center;
    color: aliceblue;
    text-shadow: 2px 2px #316fae;
    font-weight: 700;
  }
  td:nth-child(even) {
    background: #f8f8f8;
  }
  th {
    display: none;
  }
  td {
    border: none !important;
    text-shadow: 2px 2px #dee2e6;
    text-align: start !important;
    display: block;
    padding: 0.3rem 0.2rem;
    font-size: 18px;
  }
  td:before {
    content: attr(data-title);
    display: inline-block;
    width: 200px;
    min-width: 200px;
    margin-right: 8rem;
    margin-left: 0.5rem;
    font-size: 18px;
  }
}
@media only screen and (max-width: 520px) {
  td:before {
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1680px) {
  .newRealTimeStyle {
    table th {
      padding: 7px 0px;
    }
  }
}

// modal 內的要用 :deep 否則樣式無法傳遞到內層(parent to child component)
:deep(.alertTable) {
  .table {
    table-layout: fixed;
    font-weight: 700;
    .thead-light {
      th {
        background-color: rgba(204, 207, 196, 0.178);
        color: rgb(17, 11, 11);
        font-weight: 700;
        font-size: 14px;
      }
    }
    th:nth-child(2) {
      width: 80%;
    }
    th {
      text-align: center;
    }
    td {
      word-break: break-all;
      text-align: center;
      padding: 10px;
    }
    td:nth-child(2) {
      text-align: left;
    }

    // alert modal level table background colors
    @each $key, $value in $level-color-array {
      .table-#{$key} {
        td:nth-child(1) {
          background-color: rgba($value, 20%);
          border-color: rgba($value, 30%);
        }
      }
    }

    // red bg adjust darken, can distinguish orange
    .table-red {
      td:nth-child(1) {
        background-color: rgba($c-red, 25%);
      }
    }
  }

  .table-hover {
    tr {
      &:hover {
        td:nth-child(1) {
          filter: brightness(95%);
        }
      }
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $acme-blue-8;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $acme-blue-8;
}
</style>
